<!--
 * @Description: 新装加装/以新换旧补录信息弹框（新）
 * @Author: ChenXueLin
 * @Date: 2021-11-22 11:10:18
 * @LastEditTime: 2022-12-26 11:56:15
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <el-dialog
      v-dialogDrag
      :title="dialogTitle"
      :visible.sync="auditVisible"
      width="1100px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      v-loading="loading"
      :element-loading-background="loadingBackground"
      custom-class="add-concat-dialog"
    >
      <div class="audit-content">
        <el-form
          ref="auditForm"
          label-width="100px"
          :model="auditForm"
          :rules="auditFormRules"
          :inline="true"
        >
          <el-form-item label="工程师" prop="engineerrpName">
            <el-input
              v-model.trim="basicInfo.engineerrpName"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="作业时间" prop="finishTime">
            <el-date-picker
              v-model="auditForm.finishTime"
              type="datetime"
              placeholder="选择日期"
              value-format="timestamp"
              popper-class="special"
              :picker-options="pickerOptions1"
              :disabled="basicInfo.fixDateStr ? true : false"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="场景名称" prop="sceneName">
            <el-input
              v-model.trim="auditForm.sceneName"
              placeholder="场景名称"
              :disabled="
                basicInfo.taskSecondClass == 2 || basicInfo.taskSecondClass == 7
              "
            ></el-input>
          </el-form-item>
          <el-form-item label="场景类型" prop="sceneType">
            <e6-vr-select
              v-model="auditForm.sceneType"
              :data="sceneTypeList"
              placeholder="场景类型"
              title="场景类型"
              clearable
              :props="{
                id: 'codeValue',
                label: 'codeName',
              }"
              :disabled="
                basicInfo.taskSecondClass == 1 && basicInfo.itemStatus != 7
                  ? false
                  : true
              "
            ></e6-vr-select>
          </el-form-item>
          <el-form-item
            label="车牌颜色"
            prop="sceneType"
            v-if="
              basicInfo.taskSecondClass == 7 &&
                (auditForm.sceneType == 1 || auditForm.sceneType == 4)
            "
          >
            <el-input disabled v-model="basicInfo.plateColorName"></el-input>
          </el-form-item>
          <el-form-item
            label="车架号"
            prop="vin"
            v-if="
              basicInfo.taskSecondClass == 1 &&
                (auditForm.sceneType == 1 || auditForm.sceneType == 4)
            "
            :rules="
              basicInfo.needVin
                ? [
                    {
                      required: true,
                      message: '请输入车架号',
                      trigger: ['blur', 'change'],
                    },
                    { validator: checkIdvin, trigger: 'blur' },
                  ]
                : {}
            "
          >
            <el-input
              v-model.trim="auditForm.vin"
              placeholder="车架号"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="车辆品牌"
            prop="vehicleBrand"
            v-if="
              basicInfo.taskSecondClass == 1 &&
                (auditForm.sceneType == 1 || auditForm.sceneType == 4)
            "
          >
            <e6-vr-select
              v-model="auditForm.vehicleBrand"
              :data="carBrandList"
              placeholder="车辆品牌"
              @change="handleVehicleBrand"
              title="车辆品牌"
              clearable
              :props="{
                id: 'brandId',
                label: 'brandName',
              }"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item
            label="车型分类"
            prop="vehicleMarker"
            v-if="
              basicInfo.taskSecondClass == 1 &&
                (auditForm.sceneType == 1 || auditForm.sceneType == 4)
            "
          >
            <e6-vr-select
              v-model="auditForm.vehicleMarker"
              :data="vehicleMarkerList"
              placeholder="车型分类"
              title="车型分类"
              clearable
              :props="{
                id: 'codeValue',
                label: 'codeName',
              }"
              @change="handleVehicleMarker"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item
            :label="
              auditForm.sceneType == 1 || auditForm.sceneType == 4
                ? '车体长'
                : '长'
            "
            prop="overallLength"
            v-if="basicInfo.taskSecondClass == 1"
          >
            <el-input
              v-model.trim="auditForm.overallLength"
              :placeholder="
                auditForm.sceneType == 1 || auditForm.sceneType == 4
                  ? '车体长'
                  : '长'
              "
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="
              auditForm.sceneType == 1 || auditForm.sceneType == 4
                ? '车体宽'
                : '宽'
            "
            prop="overallWidth"
            v-if="basicInfo.taskSecondClass == 1"
          >
            <el-input
              v-model.trim="auditForm.overallWidth"
              :placeholder="
                auditForm.sceneType == 1 || auditForm.sceneType == 4
                  ? '车体宽'
                  : '宽'
              "
            ></el-input>
          </el-form-item>
          <el-form-item
            :label="
              auditForm.sceneType == 1 || auditForm.sceneType == 4
                ? '车体高'
                : '高'
            "
            prop="overallHeight"
            v-if="basicInfo.taskSecondClass == 1"
          >
            <el-input
              v-model.trim="auditForm.overallHeight"
              :placeholder="
                auditForm.sceneType == 1 || auditForm.sceneType == 4
                  ? '车体高'
                  : '高'
              "
            ></el-input>
          </el-form-item>
          <el-form-item
            label="场景地址"
            prop="sceneAddress"
            v-if="
              basicInfo.taskSecondClass == 1 &&
                (auditForm.sceneType == 2 || auditForm.sceneType == 3)
            "
          >
            <el-input
              v-model.trim="auditForm.sceneAddress"
              placeholder="场景地址"
              :disabled="basicInfo.taskSecondClass != 1"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="车牌颜色"
            prop="plateColor"
            v-if="
              basicInfo.taskSecondClass == 1 &&
                (auditForm.sceneType == 1 || auditForm.sceneType == 4)
            "
          >
            <e6-vr-select
              v-model="auditForm.plateColor"
              :data="colorList"
              placeholder="车牌颜色"
              title="车牌颜色"
              @change="handleColor"
              :props="{
                id: 'colorId',
                label: 'colorName',
              }"
              virtual
              clearable
              :is-title="true"
            ></e6-vr-select>
          </el-form-item>
        </el-form>
        <!-- 安装明细 -->
        <template>
          <div class="detail-title">安装明细</div>
          <el-table :data="equipDetailTableData">
            <el-table-column
              show-overflow-tooltip
              v-for="(column, index) in columnData"
              :key="index"
              :prop="column.fieldName"
              :label="column.fieldLabel"
              :min-width="column.width"
              :fixed="column.fixed"
              :align="column.align"
              header-align="center"
            >
              <template slot-scope="{ row, $index }">
                <span
                  v-if="
                    column.fieldName === 'equipCode' &&
                      row.isBatchManage &&
                      (basicInfo.taskSecondClass == 1 ||
                        basicInfo.taskSecondClass == 2 ||
                        ([7, 19, 20].indexOf(basicInfo.taskSecondClass) > -1 &&
                          (row.equipOp == 3 || row.equipOp == 7)))
                  "
                >
                  <el-button
                    :disabled="row.isReadOnly == 1 || row.isAddInstallHost == 1"
                    type="text"
                    @click="clickEquipNo(row)"
                    >{{
                      row.equipCode ? row.equipCode : "请选择设备编号"
                    }}</el-button
                  >
                  <i
                    @click="clearEquip(row)"
                    v-if="
                      (row.equType != 1 && basicInfo.taskSecondClass == 1) ||
                        basicInfo.taskSecondClass == 2
                    "
                    class="el-icon-circle-close"
                    style="font-size:14px;"
                  ></i>
                </span>
                <span
                  v-else-if="
                    column.fieldName == 'cardOpName' &&
                      (row.isAddInstallHost == 1 ||
                        ((basicInfo.taskSecondClass == 19 ||
                          basicInfo.taskSecondClass == 20) &&
                          row.equipOp == 0))
                  "
                >
                  <e6-vr-select
                    v-model="row.cardOp"
                    :data="cardOpList"
                    placeholder="卡操作"
                    title="卡操作"
                    :props="{
                      id: 'codeValue',
                      label: 'codeName',
                    }"
                    @change="
                      (val, node) => {
                        handleCard(val, node, row);
                      }
                    "
                    :disabled="row.isReadOnly == 1"
                  ></e6-vr-select>
                </span>
                <span
                  v-else-if="
                    column.fieldName === 'simNo' &&
                      row.equType == 1 &&
                      (row.cardOp != 0 || !row.simNo)
                  "
                >
                  <el-input
                    v-model.trim="row.simNo"
                    placeholder="请输入SIM卡号"
                    :disabled="row.isReadOnly == 1 || row.disabledSim"
                  ></el-input>
                </span>
                <span
                  v-else-if="
                    column.fieldName === 'equipOpName' &&
                      basicInfo.taskSecondClass == 7
                  "
                >
                  {{ row.equipOpName }}
                </span>
                <span
                  v-else-if="
                    column.fieldName === 'commids' &&
                      row.equType == 1 &&
                      (basicInfo.sourceOrganization == 1 ||
                        (basicInfo.sourceOrganization == 2 && !row.imei))
                  "
                >
                  <el-input
                    v-model.trim="row.commids"
                    :disabled="
                      row.isReadOnly == 1 ||
                      row.disabledCommids ||
                      row.isAutoObtain ||
                      row.isAddInstallHost == 1
                        ? true
                        : false
                    "
                    maxlength="20"
                  ></el-input>
                </span>
                <span v-else-if="column.fieldName === 'insbodyName'">
                  <new-e6-vr-select
                    v-model="row.insbody"
                    :data="row.positionList"
                    placeholder="安装位置"
                    title="安装位置"
                    clearable
                    :disabled="row.isReadOnly == 1 || row.isAddInstallHost == 1"
                    :props="{
                      id: 'id',
                      label: 'insPlace',
                    }"
                    @inputFocus="inputFocus(row, $index)"
                  ></new-e6-vr-select>
                </span>
                <span v-else>{{ row[column.fieldName] }}</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <!-- 作业图片 -->
        <template>
          <div class="title">上传凭证</div>
          <div class="img-content">
            <div class="img-item" v-for="(item, index) in imgList" :key="index">
              <uploadOBS
                class="avatar-uploader"
                :fileType="2"
                :limitSize="10"
                :showFileList="false"
                :showBtnType="0"
                :canRemove="false"
                :limitConfig="['jpeg', 'png', 'bmp', 'gif', 'jpg']"
                @success="
                  (res) => {
                    handleAvatarSuccess(res, item);
                  }
                "
              >
                <div slot="preview">
                  <img
                    v-if="item.fileUrl"
                    :src="item.fileUrl"
                    class="avatar"
                    :preview-src-list="item.fileUrl"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </div>
              </uploadOBS>
              <div class="img-title">{{ item.photoPositionName }}</div>
            </div>
          </div>
        </template>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
        <el-button
          type="primary"
          @click="sendBack"
          v-if="!basicInfo.fixDate && basicInfo.itemStatus == 7"
          >回到未分配</el-button
        >
      </div>
    </el-dialog>
    <!-- 更换设备弹框新 -->
    <new-change-equip
      :changeEquipVisible="changeEquipVisible"
      @close="close"
      :equipRow="equipRow"
      :basicInfo="basicInfo"
      @updateEquip="updateEquip"
      :secondClassName="equipRow.secondClassName"
      :thirdClassName="equipRow.thirdClassName"
    ></new-change-equip>
  </div>
</template>

<script>
import base from "@/mixins/base";
import uploadOBS from "@/components/uploadOBS/uploadOBS";
import {
  findDownList,
  newAddCollection,
  equipUpdateOwnerAndHave,
  checkAllEquip,
  equInsPlaceList,
  getCarBrand,
  getCarColor,
  getEquipBindInfo,
} from "@/api";

import { printError } from "@/utils/util";
import dialogMixins from "../mixins/index";
import newChangeEquip from "../newChangeEquip.vue";
export default {
  name: "AddDialogNew",
  components: { uploadOBS, newChangeEquip },
  data() {
    //自定义校验规则
    var checkIdvin = (rule, value, cb) => {
      const vinRegex = /[a-zA-Z0-9]{17}$/;
      if (vinRegex.test(value)) {
        return cb();
      }
      cb(new Error("只能输入数字或字母"));
    };

    return {
      checkIdvin,
      loading: false,
      simList: [],
      engineerrpName: "", //工程师名称
      imageUrl: "",
      vehicleMarkerList: [], //车辆分类下拉框
      sceneTypeList: [], //场景类型
      equipCodeList: [], //设备编号下拉框
      carBrandList: [], //车辆品牌下拉框
      cardOpList: [], //卡操作类型
      pickerOptions1: {
        disabledDate(time) {
          let currentDate = new Date();
          // 将当前月份减1，得到上个月的日期
          currentDate.setMonth(currentDate.getMonth());
          // 将日期设置为上个月的最后一天
          currentDate.setDate(0);
          // 将时间设置为上个月最后一天的最后一秒
          currentDate.setSeconds(59);
          currentDate.setMinutes(59);
          currentDate.setHours(23);
          const firstDay = new Date(currentDate).getTime();
          var now = new Date();
          // 将月份设置为下个月，日期设置为0，即本月最后一天
          now.setMonth(now.getMonth() + 1);
          now.setDate(0);
          // 将时间设置为最后一秒
          now.setSeconds(59);
          now.setMinutes(59);
          now.setHours(23);
          const lastDay = new Date(now).getTime();
          let timeStamp = moment(time).valueOf();
          // 创建一个新的Date对象，默认为当前日期和时间
          return timeStamp <= firstDay || timeStamp > lastDay;
        },
      },
      auditForm: {
        finishTime: "", //作业时间
        sceneName: "", //	场景名称
        sceneType: "", //场景类型1车，2门店，3仓库
        vin: "", //车架号
        vehicleBrand: "", //车辆品牌
        vehicleBrandName: "",
        insideLength: "", //	车厢长度（cm）
        overallLength: "", //	长
        overallWidth: "", //	宽
        overallHeight: "", //	高
        plateColor: "", //车牌颜色id
        plateColorName: "",
        sceneAddress: "", //地址
        vehicleMarker: "", //	车型分类(1普货厢车 2冷藏厢车 3半挂牵引车（普货） 4半挂牵引车（冷藏） 5危险品运输 其它)
        vehicleMarkerName: "",
      },
      colorList: [], //车牌颜色
      auditFormRules: {
        expecttime: [
          {
            required: true,
            message: "请选择作业时间",
            trigger: ["blur", "change"],
          },
        ],
        sceneName: [
          {
            required: true,
            message: "请输入场景名称",
            trigger: ["blur", "change"],
          },
        ],
        plateColor: [
          {
            required: true,
            message: "请选择车牌颜色",
            trigger: ["blur", "change"],
          },
        ],
        sceneType: [
          {
            required: true,
            message: "请选择场景类型",
            trigger: ["blur", "change"],
          },
        ],
        vehicleMarker: [
          {
            required: true,
            message: "请选择车型分类",
            trigger: ["blur", "change"],
          },
        ],
      },
      equipRow: {}, //当前选择的行
      imgList: [],
      simVisible: false,
      /*******安装明细********/
      columnData: [
        {
          fieldName: "equipCode",
          display: true,
          fieldLabel: "设备编号",
          width: 260,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "equipOpName",
          display: true,
          fieldLabel: "设备操作",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "cardOpName",
          display: true,
          fieldLabel: "卡操作",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "simNo",
          display: true,
          fieldLabel: "SIM卡号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "commids",
          display: true,
          fieldLabel: "中心识别码",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "insbodyName",
          display: true,
          fieldLabel: "安装位置",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "thirdClassName",
          display: true,
          fieldLabel: "商品名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "firstClassName",
          display: true,
          fieldLabel: "商品分类",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "secondClassName",
          display: true,
          fieldLabel: "商品开票名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "installStatusName",
          display: true,
          fieldLabel: "安装状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "equCombination",
          display: true,
          fieldLabel: "安装组合",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
      ], //作业明细表头
      positionList: [], //安装位置下拉框
      equipDetailTableData: [],
      changeEquipVisible: false, //选择设备弹框
    };
  },
  props: ["auditVisible", "basicInfo", "dialogType"],
  mixins: [base, dialogMixins],
  computed: {
    dialogTitle() {
      let title = "补录信息";
      if (this.dialogType == 1) {
        if (this.basicInfo.taskSecondClass == 1) {
          title = "新装装车";
        } else {
          title = "加装装车";
        }
      }
      if (this.dialogType == 2) {
        title = "维修";
      }
      return title;
    },
  },
  watch: {
    auditVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.initData();
          this.getInstallDetail();
          this.getCredentialReq();
          this.auditForm.sceneAddress = this.basicInfo.sceneAddress;
          this.auditForm.sceneName = this.basicInfo.sceneName;
          this.auditForm.sceneType = this.basicInfo.sceneType.toString();
          this.auditForm = {
            ...this.auditForm,
            ...this.basicInfo.taskItemOtherInfoRespVO,
          };
          this.auditForm.vehicleMarker = this.auditForm.vehicleMarker
            ? this.auditForm.vehicleMarker.toString()
            : "";
          this.auditForm.vehicleBrand = this.auditForm.vehicleBrand
            ? this.auditForm.vehicleBrand
            : "";
          this.auditForm.plateColorName = this.basicInfo.plateColorName;
          this.auditForm.plateColor = this.basicInfo.plateColor
            ? this.basicInfo.plateColor
            : "";
          this.auditForm.finishTime = this.basicInfo.fixDateStr
            ? this.basicInfo.fixDateStr
            : this.dialogType == 1
            ? new Date().getTime()
            : "";
          this.$nextTick(() => {
            this.$refs["auditForm"].clearValidate();
          });
        }
      },
    },
    // //车辆品牌
    // "auditForm.vehicleBrand": {
    //   immediate: true,
    //   handler(val) {
    //     if (val && this.carBrandList.length) {
    //       this.auditForm.vehicleBrandName = this.carBrandList.find(item => {
    //         return item.brandId == val;
    //       }).brandName;
    //     }
    //   }
    // }
  },
  methods: {
    //选择车辆品牌
    handleVehicleBrand(flag, node) {
      this.auditForm.vehicleBrandName = node.label;
    },
    //选择车牌颜色
    handleColor(flag, node) {
      this.auditForm.plateColorName = node.label;
    },
    //选择车型分类
    handleVehicleMarker(flag, node) {
      this.auditForm.vehicleMarkerName = node.label;
    },

    //获取工程师所有信息
    async initData() {
      try {
        this.loading = true;
        let promiseList = [
          //车辆分类
          findDownList(["vehicleType", "sceneType", "cardOp"]),
          //获取车辆品牌
          getCarBrand(),
          getCarColor(),
          // //获取工程师名下sim卡
          // engineerSimList({
          //   id: this.basicInfo.engineerrpId
          // })
        ];
        let [
          vehicleMarkerRes,
          carBrandRes,
          colorRes,
          // simRes
        ] = await Promise.all(promiseList);
        //车辆分类下拉框
        this.vehicleMarkerList = this.getFreezeResponse(vehicleMarkerRes, {
          path: "data.vehicleType",
        });
        //场景类型下拉框
        this.sceneTypeList = this.getFreezeResponse(vehicleMarkerRes, {
          path: "data.sceneType",
        });
        //车辆品牌下拉框
        this.carBrandList = this.getFreezeResponse(carBrandRes, {
          path: "data",
        });
        //车辆颜色下拉框
        this.colorList = this.getFreezeResponse(colorRes, {
          path: "data",
        });
        this.cardOpList = this.getFreezeResponse(vehicleMarkerRes, {
          path: "data.cardOp",
        });
        //客户名下SIm卡
        // this.simList = this.getFreezeResponse(simRes, {
        //   path: "data"
        // });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //选中换卡
    handleCard(val, node, row) {
      if (row.cardOp == 3 || row.cardOp == 2) {
        this.equipRow = row;
        // this.simVisible = true;
      } else {
        row.simNo = "";
        row.simId = "";
      }
    },
    //点击设备编号
    clickEquipNo(row) {
      if (!row.isBatchManage) return;
      this.changeEquipVisible = true;
      this.equipRow = row;
    },
    //清楚设备编号
    clearEquip(row) {
      this.equipRow = row;
      this.equipRow.equipCode = "";
      this.equipRow.newEquipCode = "";
    },
    //请求获取设备编号下拉框数据
    async getEquipList(row) {
      try {
        let res = await equInsPlaceList({
          firstClassId: row.firstClassName,
          secondClassId: row.secondClassId,
        });
        this.equipList = this.getFreezeResponse(res, {
          path: "data",
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击安装位置选择框
    inputFocus(row, index) {
      if (!row.positionList.length) {
        this.getPosition(row, index);
      }
    },
    //请求获取安装位置下拉框
    async getPosition(row, index) {
      try {
        let res = await equInsPlaceList({
          firstClassId: row.firstClassId,
          secondClassId: row.secondClassId,
        });
        this.equipDetailTableData[index].positionList = this.getFreezeResponse(
          res,
          {
            path: "data",
          }
        );
        // console.log(this.equipDetailTableData, " this.equipDetailTableData==");
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //重置form
    resetForm() {
      this.auditForm = {
        expecttime: "", //作业时间
        sceneName: "", //	场景名称
        sceneType: "", //场景类型1车，2门店，3仓库
        vin: "", //车架号
        vehicleBrand: "", //车辆品牌
        vehicleBrandName: "",
        insideLength: "", //	车厢长度（cm）
        overallLength: "", //	长
        overallWidth: "", //	宽
        overallHeight: "", //	高
        vehicleMarker: "", //	车型分类(1普货厢车 2冷藏厢车 3半挂牵引车（普货） 4半挂牵引车（冷藏） 5危险品运输 其它)
        vehicleMarkerName: "",
        plateColorName: "",
        plateColor: "",
      };
      this.equipDetailTableData = [];
      this.$refs.auditForm.resetFields();
      this.imgList = [];
    },
    //点击确定
    handleConfirm() {
      this.$refs.auditForm.validate((valid) => {
        if (valid) {
          this.equipDetailTableData.map((item) => {
            if (item.insbody) {
              let obj = item.positionList.find((each) => {
                return each.id == item.insbody;
              });
              item.insbodyName =
                obj && obj.label
                  ? obj.label
                  : item.insbodyName
                  ? item.insbodyName
                  : "";
            }
          });

          if (this.basicInfo.taskSecondClass == 1) {
            //新装
            if (
              this.equipDetailTableData.filter(
                (item) => item.equType == 1 && !item.equipCode
              ).length
            ) {
              //新装如果是主机必须选设备编号
              this.$message.warning("请检查主机设备编号是否全部选择");
              return;
            }
            if (
              this.equipDetailTableData.filter(
                (item) => item.equType == 1 && !item.insbody
              ).length
            ) {
              //新装如果是主机必须选安装位置
              this.$message.warning("请检查主机安装位置是否全部选择");
              return;
            }
          }

          if (this.basicInfo.taskSecondClass == 2) {
            //加装
            if (
              this.equipDetailTableData.every((item) => !item.equipCode) &&
              this.basicInfo.sourceOrganization != 2 &&
              (this.basicInfo.taskSource == 1 || this.basicInfo.taskSource == 2)
            ) {
              //加装必须录入一个设备
              this.$message.warning("加装必须至少录入一个设备");
              return;
            }
            // let filterArr = this.equipDetailTableData.filter(
            //   item => item.equipCode
            // );
            // if (filterArr.some(item => !item.insbody)) {
            //   //加装必须录入设备必须填写安装位置
            //   this.$message.warning("录入得设备需填写安装位置");
            //   return;
            // }
          }
          if (this.basicInfo.taskSecondClass == 7) {
            //以新换旧
            if (
              this.equipDetailTableData.filter(
                (item) =>
                  !item.equipCode &&
                  (item.equipOp == 3 || item.equipOp == 7) &&
                  item.isBatchManage
              ).length
            ) {
              //新换旧设备编号必填
              this.$message.warning("请检查设备编号是否全部选择");
              return;
            }
            if (
              this.equipDetailTableData.some(
                (item) =>
                  item.installDetailId != 0 &&
                  !item.insbody &&
                  (item.equipOp == 3 || item.equipOp == 7)
              )
            ) {
              //新换旧安装位置必填
              this.$message.warning("请检查安装位置是否全部选择");
              return;
            }
          }

          //主机设备
          let equipTypeList = this.equipDetailTableData.filter((item) => {
            return item.equType == 1;
          });
          //检验sim卡位数
          let simRegx = /^(\d{11}|\d{13})$/;
          if (
            !(
              this.basicInfo.taskSecondClass == 2 &&
              this.basicInfo.sourceOrganization == 1
            ) &&
            this.basicInfo.taskSecondClass != 19
          ) {
            if (equipTypeList.some((item) => !simRegx.test(item.simNo))) {
              this.$message.warning(
                "SIM卡号只能是由11位或13位纯数字组成，请修改后重新提交"
              );
              return;
            }
          }
          if (
            (this.basicInfo.taskSecondClass == 19 ||
              this.basicInfo.taskSecondClass == 20) &&
            equipTypeList.some((item) => item.cardOp == 3)
          ) {
            if (equipTypeList.some((item) => !simRegx.test(item.simNo))) {
              this.$message.warning(
                "SIM卡号只能是由11位或13位纯数字组成，请修改后重新提交"
              );
              return;
            }
          }
          if (
            this.basicInfo.sourceOrganization != 2 &&
            equipTypeList.some((item) => {
              const reg = /^[a-zA-Z0-9:]{1,20}$/;
              return item.isAddInstallHost != 1 && !reg.test(item.commids);
            })
          ) {
            this.$message.warning(
              "主机的中心识别应为1~20位数字,字母,英文冒号单独或组合"
            );
            return;
          }

          if (
            this.equipDetailTableData.some(
              (item) => item.isChangeSim == 1 && item.cardOp == 0
            )
          ) {
            this.$confirm(
              "SIM卡已超1.5年，请点“取消”换卡，无需换卡请点“确定”",
              "提示",
              {
                type: "warning",
              }
            ).then(() => {
              if (this.basicInfo.isReportMsg) {
                this.$confirm(
                  "由于安装物料被更新，需重新上报耗材，若已重新上报请忽略！",
                  "提示",
                  {
                    confirmButtonText: "我知道了",
                    showCancelButton: false,
                    type: "warning",
                  }
                ).then(() => {
                  this.handleSubmit();
                });
              } else {
                this.handleSubmit();
              }
            });
          } else {
            if (this.basicInfo.isReportMsg) {
              this.$confirm(
                "由于安装物料被更新，需重新上报耗材，若已重新上报请忽略！",
                "提示",
                {
                  confirmButtonText: "我知道了",
                  showCancelButton: false,
                  type: "warning",
                }
              ).then(() => {
                this.handleSubmit();
              });
            } else {
              this.handleSubmit();
            }
          }
        }
      });
    },
    handleSubmit() {
      let msg = this.dialogType == 1 ? "装车信息" : "补录信息";
      this.$confirm(`是否确认提交${msg}，提交后将不能再更改！`, `${msg}确认`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.checkAllEquipReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //如果查询的是所有设备，需校验是否可用
    async checkAllEquipReq() {
      try {
        this.loading = true;
        let equipCodeList = this.equipDetailTableData.map(
          (item) => item.equipCode
        );
        let res = await checkAllEquip({
          equipCodeList,
          engineerrpId: this.basicInfo.engineerrpId,
          itemId: this.basicInfo.itemId,
        });
        if (res.code == "OK") {
          if (res.data.isTips) {
            //需要提示的调用流转接口
            this.$confirm(res.data.tips, "", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.noEngineerEquipCodeList = res.data.noEngineerEquipCodeList;
                this.wareHouseEquipCodeList = res.data.wareHouseEquipCodeList;
                this.noProjectEquipCodeList = res.data.noProjectEquipCodeList;
                // this.equipUpdateOwnerAndHaveReq();
                this.newAddCollection();
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
          } else {
            //不需要提示的直接提交
            this.newAddCollection();
          }
        }
      } catch (error) {
        this.loading = false;
        printError(error);
      }
    },
    //设备流转
    async equipUpdateOwnerAndHaveReq() {
      try {
        this.loading = true;
        let res = await equipUpdateOwnerAndHave({
          noEngineerEquipCodeList: this.noEngineerEquipCodeList,
          wareHouseEquipCodeList: this.wareHouseEquipCodeList,
          noProjectEquipCodeList: this.noProjectEquipCodeList,
          engineerrpId: this.basicInfo.engineerrpId,
        });
        if (res.code == "OK") {
          this.newAddCollection();
        }
      } catch (error) {
        this.loading = false;
        printError(error);
      }
    },
    //新装、加装-补录信息
    async newAddCollection() {
      try {
        this.loading = true;
        let supplementEuqReqVOList = this.equipDetailTableData;
        let res = await newAddCollection({
          ...this.auditForm,
          itemId: this.basicInfo.itemId,
          supplementEuqReqVOList,
          workStartTime: this.auditForm.finishTime,
          photoLocationReqVOList: this.imgList,
          versionNumber: this.basicInfo.versionNumber,
          taskSecondClass: this.basicInfo.taskSecondClass,
        });
        if (res.code == "OK") {
          this.$message.success("操作成功");
          this.resetForm();
          this.$emit("refresh");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //更新设备编号
    updateEquip(newEquip) {
      if (
        this.equipDetailTableData.some((item) => {
          return (
            item.equipCode == newEquip.equipNo ||
            item.equipCode == newEquip.equipCode
          );
        })
      ) {
        this.$message.warning("更换设备不能重复");
        return;
      }
      this.equipRow.equipCode = newEquip.equipNo || newEquip.equipCode;
      this.equipRow.newEquipCode = newEquip.equipNo || newEquip.equipCode;
      this.equipRow.equSource = newEquip.equSource;
      this.changeEquipVisible = false;
      if (this.equipRow.equType == 1) {
        this.getEquipBindInfoReq(newEquip);
      } else {
        this.equipRow.equipCode = newEquip.equipNo || newEquip.equipCode;
        this.equipRow.newEquipCode = newEquip.equipNo || newEquip.equipCode;
        this.equipRow.equSource = newEquip.equSource;
      }
    },
    //根据设备编号查询sim
    async getEquipBindInfoReq(newEquip) {
      try {
        let res = await getEquipBindInfo({
          equipCode: newEquip.equipNo || newEquip.equipCode,
        });
        if (res.code == "OK") {
          let data = res.data;
          this.equipRow.imei = data.imei;
          if (data.simNo) {
            // if (this.basicInfo.sourceOrganization == 1 && !data.simId) {
            //   this.equipRow.newEquipCode = "";
            //   this.equipRow.equipCode = "";
            //   this.equipRow.simNo = "";
            //   this.equipRow.simId = "";
            //   this.equipRow.isSelfOwn = "";
            //   this.$message.warning("请联系供应链赵小兵在E3 2.0采购入库SIM");
            //   return;
            // } else {
            this.equipRow.simNo = data.simNo;
            this.equipRow.simId = data.simId;
            this.equipRow.isSelfOwn = data.isSelfOwn;
            this.equipRow.disabledSim = true;
            // }
          } else {
            this.equipRow.simNo = "";
            this.equipRow.simId = "";
            this.equipRow.isSelfOwn = "";
            this.equipRow.disabledSim = false;
          }
          //设备上线易流云， 没有IMEI， 没有中心识别码： 需要录入中心识别码
          // 设备上线易流云， 有IMEI， 没有中心识别码：需要录入中心识别码
          // 设备上线易流云， 有IMEI， 有中心识别码： 不需要录入中心识别码
          // 设备上线G7S， 没有IMEI， 没有中心识别码：需要录入中心识别码
          // 设备上线G7S， 有IMEI， 没有中心识别码： 不需要录入中心识别码
          // 设备上线G7S， 有IMEI， 有中心识别码： 不需要录入中心识别码
          // sourceOrganization上线平台（1E6yun，2G7s）
          let sourceOrganization = this.basicInfo.sourceOrganization;
          this.equipRow.commids = data.commIds;
          this.equipRow.centerId = data.commIds;
          this.equipRow.isAutoObtain = data.commIds ? 1 : 0;
          if (sourceOrganization == 1 && !data.commIds) {
            this.equipRow.disabledCommids = false;
          }
          if (sourceOrganization == 1 && data.imei && data.commIds) {
            this.equipRow.disabledCommids = true;
          }
          if (sourceOrganization == 2 && !data.imei && !data.commIds) {
            this.equipRow.disabledCommids = false;
          }
          if (sourceOrganization == 2 && data.imei) {
            this.equipRow.disabledCommids = true;
          }
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleClose() {
      this.$emit("handleClose", "auditVisible");
    },

    close() {
      this.changeEquipVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.audit-content {
  margin-top: 20px;

  .title {
    padding-left: 20px;
    box-sizing: border-box;
    // margin-bottom: 15px;
    margin-top: 15px;
  }

  .detail-title {
    padding-left: 20px;
    box-sizing: border-box;
    margin-bottom: 15px;

    &::before {
      content: "*";
      color: #f56c6c;
      margin-right: 4px;
    }
  }

  .img-content {
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    // margin-top: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;

    /deep/ .img-item {
      margin-right: 15px;
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .avatar-uploader {
        .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;

          &:hover {
            border-color: #409eff;
          }
        }

        .avatar-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 110px;
          height: 90px;
          line-height: 90px;
          text-align: center;
        }

        .avatar {
          width: 110px;
          height: 90px;
          display: block;
        }
      }

      .img-title {
        width: 100%;
        text-align: center;
        margin-top: 5px;
      }
    }
  }
}
</style>
